<template>
  <div class="container">
    <div class="content-box">
      <h1>Social media</h1>

      <div>Follow us on <a href="https://twitter.com/GuildsAndBlades" target="_blank"
                           class="social-link">Twitter</a>
        or join <a href="https://discord.gg/ARkGUhN73T" target="_blank"
                   class="social-link">Discord</a> to access the beta testing phase!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  metaInfo () {
    return {
      title: 'Guilds & Blades - Social Media'
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

h3 {
  margin-top: $spacing3;
}

mark {
  background-color: $colorLink;
}
</style>
